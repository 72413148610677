import React, { useEffect, useState, useCallback } from 'react';

import { telefoneContato, horarioAtendimento } from 'config';
import moment from 'moment';
import PropTypes from 'prop-types';

import DetalhesModal from '@commons/components/DetalhesModal';
import HistoricoLances from '@commons/components/HistoricoLances';
import HtmlTooltip from '@commons/components/HtmlTooltip';
import Alerts from '@commons/components/LoteAlerts';
import Carousel from '@commons/components/LoteCarousel';
import history from '@commons/services/history';
import { pusher } from '@commons/services/pusher';
import { statusTexts, statusParaFecharLances } from '@commons/utils/constants';
import { currency } from '@commons/utils/formatters';

import Acoes from './Acoes';
import {
  RightSide,
  DetailTitle,
  DetailSubtitle,
  InfoIcon,
  ArrowBack,
  ArrowForward,
  LittleInfo,
  Footer,
  PhoneIcon,
  LeftSide,
  LancesContainer,
  HorarioAtendimento,
} from './styles';

export const Lances = ({ lote, id, setLote }) => {
  const [detalhesModalOpen, setDetalhesModalOpen] = useState(false);

  const [channel] = useState(() => pusher.subscribe('tradeleiloes'));

  useEffect(() => {
    return () => {
      console.log('Unsubscribing from channel...');

      pusher.unsubscribe('tradeleiloes');
    };
  }, []);

  useEffect(() => {
    if (lote.lances?.length > 5) {
      setLote(previousState => {
        return {
          ...previousState,
          lances: previousState.lances.slice(0, 5),
        };
      });
    }
    //eslint-disable-next-line
  }, [lote.lances]);

  useEffect(() => {
    console.log('Binding to channels...');

    channel.bind(`lances-evento${lote.evento.id}lote${id}`, function(lance) {
      if (lance) {
        setLote(previousState => {
          return {
            ...previousState,
            lances: [lance, ...previousState.lances],
          };
        });
      }
    });

    channel.bind(`status-evento${lote.evento.id}lote${id}`, function(status) {
      if (status) {
        setLote(previousState => ({
          ...previousState,
          status_id: Number(status),
          status: {
            ...previousState.status,
            nome: statusTexts[status],
          },
        }));
      }
    });

    channel.bind(`condicao-evento${lote.evento.id}lote${id}`, function(
      condicao
    ) {
      if (condicao) {
        setLote(previousState => ({
          ...previousState,
          condicao: condicao,
        }));
      }
    });

    return () => {
      console.log('Unbinding from channels...');

      channel.unbind(`lances-evento${lote.evento.id}lote${id}`);
      channel.unbind(`status-evento${lote.evento.id}lote${id}`);
    };
    //eslint-disable-next-line
  }, [id, lote.evento.id]);

  const lanceMinimo = useCallback(
    bens => {
      let lance_minimo = 0;

      const dataPrimeiroLeilao = moment(lote.evento.data_primeiro_leilao);
      const hoje = moment();

      const key = hoje.startOf('day').isAfter(dataPrimeiroLeilao.endOf('day'))
        ? 'valor_segundo_leilao'
        : 'valor_primeiro_leilao';

      bens.forEach(bem => {
        if (bem?.retirado !== 'Y') {
          lance_minimo += parseFloat(bem[key]);
        }
      });

      return lance_minimo;
    },
    [lote.evento.data_primeiro_leilao]
  );

  const handleNextClick = () => {
    const idProximo = lote.proximo?.id;

    if (idProximo) {
      history.replace(`/lotes/${idProximo}`);
    }
  };

  const handlePreviousClick = () => {
    const idAnterior = lote.anterior?.id;

    if (idAnterior) {
      history.replace(`/lotes/${idAnterior}`);
    }
  };

  const nextLoteTooltip = (
    <>
      {lote.proximo ? (
        <>
          <div>
            <b>Próximo = Lote {lote.proximo?.numero}</b>
          </div>
          <div>{lote.proximo?.nome}</div>
          {lote.proximo?.sucata === 'Y' && (
            <div>
              <b>Qtd itens</b>: {lote.proximo.bens?.length}
            </div>
          )}
          <div>
            <b>Lance mínimo inicial</b>:{' '}
            {currency(lanceMinimo(lote.proximo.bens))}
          </div>
        </>
      ) : (
        <div>Sem próximo lote</div>
      )}
    </>
  );

  const previousLoteTooltip = (
    <>
      {lote.anterior ? (
        <>
          <div>
            <b>Anterior = Lote {lote.anterior?.numero}</b>
          </div>
          <div>{lote.anterior?.nome}</div>
          {lote.anterior?.sucata === 'Y' && (
            <div>
              <b>Qtd itens</b>: {lote.anterior.bens?.length}
            </div>
          )}
          <div>
            <b>Lance mínimo inicial</b>:{' '}
            {currency(lanceMinimo(lote.anterior.bens))}
          </div>
        </>
      ) : (
        <div>Sem lote anterior</div>
      )}
    </>
  );

  return (
    <>
      <LancesContainer status={lote.status_id}>
        <LeftSide>
          <Acoes
            //incrementoMinimo={lote?.evento?.incremento_minimo}
            incrementoMinimo={lote?.incremento_minimo>0?lote?.incremento_minimo:lote?.evento?.incremento_minimo}
            ultimoLance={{
              valor: lote?.lances?.length
                ? ' ' + lote?.lances[0]['valor']
                : ' ' + lote?.ultimo_lance,
              nome: lote?.lances?.length ? lote?.lances[0]['user'] : '',
            }}
            lanceMinimoInicial={lanceMinimo(lote?.bens)}
            id={id}
            condicao={lote?.condicao}
            comissao={lote?.evento?.comissao}
            taxa={lote?.lances?.length?lote.lances[0]['valor']<=lote.limite1?lote.taxa1:lote.lances[0]['valor']>lote.limite1?lote.taxa2:0:0}
            // Tradução dos status em utils/constants
            isOpen={statusParaFecharLances.indexOf(lote?.status_id) === -1}
          />
        </LeftSide>

        <RightSide>
          <DetailTitle>
            <HtmlTooltip tooltipContent={previousLoteTooltip}>
              <ArrowBack onClick={handlePreviousClick} />
            </HtmlTooltip>
            Lote {lote.numero}
            <HtmlTooltip tooltipContent={nextLoteTooltip}>
              <ArrowForward onClick={handleNextClick} />
            </HtmlTooltip>
          </DetailTitle>

          <DetailSubtitle>
            {lote.nome}
            <HtmlTooltip tooltipContent="Clique para mais informações">
              <InfoIcon onClick={() => setDetalhesModalOpen(true)} />
            </HtmlTooltip>
          </DetailSubtitle>

          <LittleInfo>Lotes restantes: {lote.lotes_restantes}</LittleInfo>

          <Alerts
            status={lote.status?.nome}
            status_id={lote.status_id}
            sucata={lote.sucata === 'Y'}
          />

          <Carousel
            lote={lote}
          />

          {/* <HistoricoLances lances={lote?.lances} /> */}

          <Footer style={{ marginTop: 50 }}>
            <DetailTitle>Plantão do Leilão</DetailTitle>
            <DetailSubtitle>
              <PhoneIcon /> {telefoneContato}
            </DetailSubtitle>
            <HorarioAtendimento>
              <p>
                <b>Horário de atendimento:</b>
              </p>
              <p>{horarioAtendimento}</p>
            </HorarioAtendimento>
          </Footer>
        </RightSide>

        <DetalhesModal
          open={detalhesModalOpen}
          onClose={() => setDetalhesModalOpen(false)}
          lote={lote}
        />
      </LancesContainer>
    </>
  );
};

Lances.propTypes = {
  lote: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default Lances;
