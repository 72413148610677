import React, { useMemo, useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';

import Button from '@commons/components/Button';
import LanceAutomatizadoModal from '@commons/components/LanceAutomatizadoModal';
import api from '@commons/services/api';
import colors from '@commons/styles/colors';
import { showErrorAlert } from '@commons/utils/alert';
import { currency } from '@commons/utils/formatters';

import RegistrarLance from './RegistrarLance';
import {
  Container,
  TopArea,
  Score,
  SegmentTitle,
  BottomArea,
  DetailsContainer,
  SubTitle,
  UltimoLance,
  Comissao,
  CondicaoPagamento,
  CondicaoPagamentoDiv,
  BidContainer,
  H4Title,
  Alert,
} from './styles';

const Acoes = ({
  incrementoMinimo,
  ultimoLance,
  lanceMinimoInicial,
  id,
  isOpen,
  condicao,
  comissao,
  taxa,
}) => {
  const [
    detalhesLanceAutomatizadoModalOpen,
    setDetalhesLanceAutomatizadoModalOpen,
  ] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [hasLanceAutomatizado, setHasLanceAutomatizado] = useState();

  const formattedComissao = useMemo(() => {
    const floatComissao = parseFloat(comissao);

    return {
      string: parseInt(comissao) + '%',
      value: floatComissao / 100,
    };
  }, [comissao]);

  const updateLancesAutomatizados = () => {
    api.get(`/lotes/lances/meuLanceAutomatizado/${id}`).then(response => {
      if (response.data !== 0) {
        const lance = response.data.find(item => !item.deleted_at);

        setHasLanceAutomatizado(lance || false);
      } else {
        setHasLanceAutomatizado(false);
      }
    });
  };

  useEffect(() => {
    updateLancesAutomatizados();
  }, [id]);

  const cancelarLanceAutomatizado = async () => {
    const response = await api.delete(
      `/lotes/lances/lanceAutomatizado/delete/${hasLanceAutomatizado.id}`,
      { validateStatus: false }
    );

    if (response.status === 200) {
      setHasLanceAutomatizado(false);
      setAlertOpen('cancelSuccess');
    } else {
      showErrorAlert({
        message:
          'Erro ao cancelar lance automatizado. Por favor, entre em contato com o plantão do leilão.',
      });
    }
  };

  const onSuccessLanceAutomatizado = response => {
    updateLancesAutomatizados();

    setAlertOpen('success');
  };

  return (
    <Container is_open={String(isOpen)}>
      <DetailsContainer>
        <TopArea>
          <div>
            <SegmentTitle>INCREMENTO MÍNIMO</SegmentTitle>
            <Score color={colors.white}>{currency(incrementoMinimo)}</Score>
          </div>
          <div>
            <SegmentTitle>LANCE MÍNIMO INICIAL</SegmentTitle>
            <Score color={colors.white}>{currency(lanceMinimoInicial)}</Score>
          </div>
          <CondicaoPagamentoDiv>
            <SegmentTitle>CONDIÇÃO</SegmentTitle>
            <CondicaoPagamento>{condicao}</CondicaoPagamento>
          </CondicaoPagamentoDiv>
        </TopArea>
        <BottomArea>
          <UltimoLance>
            <SegmentTitle>ÚLTIMO LANCE</SegmentTitle>
            <Score color={colors.yellow}>{currency(ultimoLance.valor)}</Score>
            {ultimoLance.nome && <p>({ultimoLance.nome})</p>}
          </UltimoLance>
          <Comissao>
            <SegmentTitle>COMISSÃO ({formattedComissao.string}) + Txs</SegmentTitle>
            <Score color={colors.white}>
              {currency((ultimoLance.valor * formattedComissao.value)+parseFloat(taxa))}
            </Score>
          </Comissao>
        </BottomArea>
      </DetailsContainer>

      {isOpen && (
        <>
          <BidContainer style={{ marginBottom: 0 }}>
            <SubTitle>REGISTRE SEU LANCE:</SubTitle>
            <RegistrarLance
              incrementoMinimo={incrementoMinimo || '10'}
              ultimoLance={ultimoLance}
              lanceMinimoInicial={lanceMinimoInicial}
              id={id}
            />
          </BidContainer>
          <BidContainer style={{ marginTop: 0 }}>
            <H4Title>OU REGISTRE UM LANCE AUTOMATIZADO:</H4Title>
            {!hasLanceAutomatizado ? (
              <Button
                style={{ margin: '20px 0' }}
                size="extra"
                onClick={() => setDetalhesLanceAutomatizadoModalOpen(true)}
              >
                CRIAR LANCE AUTOMATIZADO
              </Button>
            ) : (
              <>
                <span style={{ fontSize: 20, color: 'white', margin: '0 5%' }}>
                  Lance automatizado cadastrado:{' '}
                  <span style={{ fontSize: 24, color: 'yellow' }}>
                    R$ {Number(hasLanceAutomatizado?.valor)}
                  </span>
                </span>
                <Button
                  style={{ margin: '20px 0' }}
                  size="extra"
                  onClick={cancelarLanceAutomatizado}
                >
                  CANCELAR LANCE AUTOMATIZADO
                </Button>
              </>
            )}
          </BidContainer>
        </>
      )}

      {detalhesLanceAutomatizadoModalOpen && (
        <LanceAutomatizadoModal
          open={detalhesLanceAutomatizadoModalOpen}
          onClose={() => setDetalhesLanceAutomatizadoModalOpen(false)}
          onSuccess={onSuccessLanceAutomatizado}
          incrementoMinimo={incrementoMinimo}
          ultimoLance={ultimoLance}
          id={id}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alertOpen === 'success'}
        onClose={() => setAlertOpen(false)}
        autoHideDuration={2000}
        key={'snack-success'}
      >
        <Alert variant="filled" onClose={() => setAlertOpen(false)}>
          Lance automatizado cadastrado com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alertOpen === 'cancelSuccess'}
        onClose={() => setAlertOpen(false)}
        autoHideDuration={2000}
        key={'snack-cancel-success'}
      >
        <Alert variant="filled" onClose={() => setAlertOpen(false)}>
          Lance automatizado cancelado com sucesso!
        </Alert>
      </Snackbar>
    </Container>
  );
};

Acoes.propTypes = {
  incrementoMinimo: PropTypes.string,
  ultimoLance: PropTypes.string,
  lanceMinimoInicial: PropTypes.number,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  condicao: PropTypes.string,
  comissao: PropTypes.string,
  taxa: PropTypes.number,
};

Acoes.defaultProps = {
  condicao: 'À vista',
};

export default Acoes;
