import React from 'react';

import PropTypes from 'prop-types';

import colors from '@commons/styles/colors';
import { currency, onlyCurrencyDigits } from '@commons/utils/formatters';

import BatidasMartelo from './BatidasMartelo';
import MetodoPagamento from './MetodoPagamento';
import {
  Container,
  TopArea,
  Score,
  SegmentTitle,
  BottomArea,
  DetailsContainer,
  UltimoLance,
  Comissao,
  SubTitle,
  ActionsContainer,
} from './styles';

const DataBoard = ({
  incrementoMinimo,
  ultimoLance,
  lanceMinimoInicial,
  lote,
}) => {
  return (
    <Container>
      <DetailsContainer>
        <TopArea>
          <div>
            <SegmentTitle>INCREMENTO MÍNIMO</SegmentTitle>
            <Score color={colors.darkYellow}>
              {currency(incrementoMinimo)}
            </Score>
          </div>
          <div>
            <SegmentTitle>LANCE MÍNIMO INICIAL</SegmentTitle>
            <Score color={colors.darkYellow}>
              {currency(lanceMinimoInicial)}
            </Score>
          </div>
          <div>
            <SegmentTitle>ÚLTIMO LANCE</SegmentTitle>
            <Score color={colors.darkYellow}>{currency(ultimoLance)}</Score>
          </div>
          <div>
          <SegmentTitle>COMISSÃO ({onlyCurrencyDigits(lote.evento.comissao)} %) </SegmentTitle>
            <Score color={colors.darkYellow}>
              {currency(ultimoLance*(lote.evento.comissao/100))}
            </Score>
          </div>
        </TopArea>
        <BottomArea>
          <UltimoLance>
            <SegmentTitle>LOTES ENCERRADOS</SegmentTitle>
            <Score color={colors.green}>{lote.lotes_fechados}</Score>
          </UltimoLance>
          <Comissao>
            <SegmentTitle>LOTES RESTANTES</SegmentTitle>
            <Score color={colors.green}>{lote.lotes_restantes}</Score>
          </Comissao>
        </BottomArea>
      </DetailsContainer>

      <SubTitle>Ações:</SubTitle>
      <ActionsContainer>
        <BatidasMartelo lote={lote} />
        <MetodoPagamento lote={lote} />
      </ActionsContainer>
    </Container>
  );
};

DataBoard.propTypes = {
  incrementoMinimo: PropTypes.string,
  ultimoLance: PropTypes.string,
  lanceMinimoInicial: PropTypes.number,
  id: PropTypes.string,
};

export default DataBoard;
